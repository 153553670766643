import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import PageHeader from '../../component/layout/pageheader';
import CommunityFormBasic from '../../component/section/communities/form_community_basic';
import Loading from '../../component/alerts/Loading';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Navigate from '../../../../util/Navigate';
import withRouter from '../../../../util/withRouter';
import PublisherHeader from '../../component/layout/publisherheader';
import { Link, useNavigate } from 'react-router-dom';

const PublisherOnboardinStep4Page = (props) => {
    const [data, setData] = useState({});
    const [templates, setTemplates] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let token = params.loginToken;

        if (token) {
            Glitch.api.Auth.oneTimeLogin(token).then(response => {
                Glitch.util.Storage.setAuthToken(response.data.data.token.access_token);
                Glitch.util.Storage.set('user_id', response.data.data.id);

                window.location.reload();
                
            }).catch(error => {
                console.error(error); // Handle any errors
            });
        }

    }, []);

    const handleNameChange = (e) => {
        const name = e.target.value;
        const subdomain = name.toLowerCase().replace(/[^a-z0-9\s-]/g, '').replace(/\s+/g, '-');
        setData({ ...data, name, subdomain });
    };

    return (
        <Fragment>
            <div className="padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Final Step <br /><br />Book A 15 Minute Onboarding Meeting</h3>
                        <hr />
                        
                        <form className="text-center" style={{ textAlign: "left" }} >
                            <br />

                            <div className='mb-2'>
                                 <a href="https://calendly.com/ddixon/glitch-onboarding" target='_blank' className='btn btn-primary'>Book Onboarding Session</a>
                           </div>

                            <br />

                            <p className='lead text-center'>All your information has been saved!</p>

                            <p className='lead text-center'>We've observed that 90% of users successfully utilize the platform after an onboarding session.</p>

                            <p className='lead text-center'>To gain full access and finalize your registration, please schedule an onboarding meeting.</p>

                            <p className='lead text-center'>Booking a meeting is free and you will NOT be charged anything.</p>
                           
                       
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default PublisherOnboardinStep4Page;
